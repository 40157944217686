import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;

function PropertyType() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'property_type'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>Property Type</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col span={4} className="card" onClick={() => handleNextPageClick('single_family')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/primary.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Single Family</h1>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('multi_family')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/secondary.svg" 
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Multi Family</h1>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('condo')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/condo.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Town Home/Condo</h1>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('mobile')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/investment.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Mobile</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default PropertyType;
