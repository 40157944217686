import { useState } from 'react';
import { Row, Col, Slider, Button, Space } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import BackButton from '../common/back_button';
import './styles.css';
import 'antd/dist/antd.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function FirstMortageBalance() {
    const [inputValue, setInputValue] = useState(150000);
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'mortgage_balance'

    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    const onValueChange = (value) => {
        setInputValue(value);
    }



    const slider_values = [90000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000, 325000, 350000, 375000, 400000, 425000, 450000, 475000, 500000, 525000, 550000, 575000, 600000, 625000, 650000, 675000, 700000, 725000, 750000, 775000, 800000, 825000, 850000, 875000, 900000, 925000, 950000, 975000, 1000000, 1025000, 1050000, 1075000, 1100000, 1125000, 1150000, 1175000, 1200000, 1225000, 1250000, 1275000, 1300000, 1325000, 1350000, 1375000, 1400000, 1425000, 1450000, 1475000, 1500000, 1525000, 1550000, 1575000, 1600000, 1625000, 1650000, 1675000, 1700000, 1725000, 1750000, 1800000]
    const marks = Object.assign({}, ...slider_values.map((x) => ({[x]: {
    style: {
      display: 'none',
    },
  },})));
    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                        <Title>Approximate Mortgage Balance</Title>
                </Col>
            </Row>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h1>
                        {
                        inputValue == 90000 ? 'Under $90,000' :
                        `$${inputValue}`
                        }
                    </h1>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Slider
                        marks={marks}
                        min={90000}
                        max={1800000}
                        onChange={onValueChange}
                        value={typeof inputValue === 'number' ? inputValue : 150000}
                        step={null}
                        dots={false}
                        tooltipVisible={false}
                    />
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={12} style={{textAlign: 'center'}}>
                    <Button type="primary" onClick={() => handleNextPageClick(inputValue)}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default FirstMortageBalance;
