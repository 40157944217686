import { useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;

function Occupation() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'occupation'

    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = () => {
        if (!inputValue) {
            message.error('Please select your occupation.')
            return;
        }
        answersUpdate(question, inputValue)
        stepUpdate()
    }

    function handleChange(value) {
        setInputValue(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your occupation?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={4}>
                    <Row justify="center">
                        <Col>
                            <Select defaultValue="Select a occupation" style={{ width: 300 }} onChange={handleChange}>
                                <Option value="Marketing">Marketing</Option>
                                <Option value="Sales/Management">Sales/Management</Option>
                                <Option value="Engineering">Engineering</Option>
                                <Option value="Science">Science</Option>
                                <Option value="Technology">Technology</Option>
                                <Option value="Construction/Skilled Worker">Construction/Skilled Worker</Option>
                                <Option value="Health Care">Health Care</Option>
                                <Option value="Teaching">Teaching</Option>
                                <Option value="Government">Government</Option>
                                <Option value="Retired">Retired</Option>
                                <Option value="Un-Employed">Un-Employed</Option>
                                <Option value="Self-Employed">Self-Employed</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default Occupation;
