import { useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;

function InterestRate() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'interest_rate'

    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = () => {
        if (!inputValue) {
            message.error('Please select your interest rate.')
            return;
        }
        answersUpdate(question, inputValue)
        stepUpdate()
    }

    function handleChange(value) {
        setInputValue(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your Interest Rate?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={4}>
                    <Row justify="center">
                        <Col>
                            <Select defaultValue="Select your interest rate" style={{ width: 300 }} onChange={handleChange}>
                                <Option value="2.00">2.00</Option>
                                <Option value="2.25">2.25</Option>
                                <Option value="2.50">2.50</Option>
                                <Option value="2.75">2.75</Option>
                                <Option value="3.00">3.00</Option>
                                <Option value="3.25">3.25</Option>
                                <Option value="3.50">3.50</Option>
                                <Option value="3.75">3.75</Option>
                                <Option value="4.00">4.00</Option>
                                <Option value="4.25">4.25</Option>
                                <Option value="4.50">4.50</Option>
                                <Option value="4.75">4.75</Option>
                                <Option value="5.00">5.00</Option>
                                <Option value="5.25">5.25</Option>
                                <Option value="5.50">5.50</Option>
                                <Option value="5.75">5.75</Option>
                                <Option value="6.00">6.00</Option>
                                <Option value="6.25">6.25</Option>
                                <Option value="6.50">6.50</Option>
                                <Option value="6.75">6.75</Option>
                                <Option value="7.00">7.00</Option>
                                <Option value="7.25">7.25</Option>
                                <Option value="7.50">7.50</Option>
                                <Option value="7.75">7.75</Option>
                                <Option value="8.00">8.00</Option>
                                <Option value="8.25">8.25</Option>
                                <Option value="8.50">8.50</Option>
                                <Option value="8.75">8.75</Option>
                                <Option value="9.00">9.00</Option>
                                <Option value="9.25">9.25</Option>
                                <Option value="9.50">9.50</Option>
                                <Option value="9.75">9.75</Option>
                                <Option value="10.00">10.00</Option>
                                <Option value="10.25">10.25</Option>
                                <Option value="10.50">10.50</Option>
                                <Option value="10.75">10.75</Option>
                                <Option value="11.00">11+</Option>



                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default InterestRate;
