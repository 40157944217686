import { useState } from 'react';
import { Row, Col, Input, Button, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function Phone() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'phone_number'
    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = (answer) => {
        if (answer.length > 10 || answer.length < 10) {
            message.error('Please enter a valid 10 digit phone number');
            return
        }
        if (!inputValue) {
            message.error('Please enter your phone number.')
            return;
        }
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's Your Phone Number?</Title>
                    <Text>Almost done!</Text>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                            <Input
                                type='number'
                                placeholder="e.g. 800555121"
                                value={inputValue}
                                onChange={(e) => {
                                    if (e.target.value.length <= 10) {
                                        setInputValue(e.target.value)}
                                    }
                                    
                                }
                                style={{ width: "300px" }}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={() => handleNextPageClick(inputValue)}>Next</Button>
                    </Col>
                </Row>
        </>
    );
}

export default Phone;
