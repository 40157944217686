import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no.svg'
import bankruptcyImage from '../../images/bankruptcy.svg'
import foreclosureImage from '../../images/foreclosure.svg'
import bothImage from '../../images/both.svg'

import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function Bankruptcy() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'bankruptcy'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>Have you had a bankruptcy or foreclosure in the last 7 years?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col xs={20} xl={4} className="card" onClick={() => handleNextPageClick('no')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={noImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>No</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('bankruptcy')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={bankruptcyImage}
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Bankruptcy</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('foreclosure')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={foreclosureImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Foreclosure</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('both')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={bothImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Both</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Bankruptcy;
