import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function CreditRating() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'credit_rating'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's Your Credit Rating?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col xs={20} xl={4} className="card" onClick={() => handleNextPageClick('excellent')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/excellent.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Excellent</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('good')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/good.svg" 
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Good</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('average')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/average.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Average</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('poor')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/bad.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Poor or Fair</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default CreditRating;
