import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function FhaLoan() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'fha_loan'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>Do you currently have FHA Loan?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col span={4} className="card" onClick={() => handleNextPageClick('yes')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={yesImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Yes</h1>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('no')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={noImage}
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>No</h1>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('I dont know')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={bothImage}
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>I don't Know</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default FhaLoan;
