import { useState } from 'react';
import { Row, Col, Button, DatePicker, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';

import moment from 'moment'
import { Typography } from 'antd';
const { Title, Text } = Typography;
const year = moment(new Date("01-01-2010"), "MM-DD-YYYY");

function HomePurchaseYear() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'home_purchase_year'

    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = () => {
        if (!inputValue) {
            answersUpdate(question, '2010')
            stepUpdate()
            return
        }
        answersUpdate(question, inputValue.year())
        stepUpdate()
    }

    function handleChange(value) {
        setInputValue(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>When did you purchase your house?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{ paddingBottom: 30 }}>
                <Col span={4}>
                    <Row justify="center">
                        <Col>
                        <DatePicker defaultValue={year} onChange={handleChange} picker="year" style={{ width: 300 }}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default HomePurchaseYear;
